<template src="./ApplyResearchGroup.html"></template>

<script>
import ChosenUniversity from "../../components/ChosenUniversity/ChosenUniversity";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import { required } from "vuelidate/lib/validators";
import idiService from "../../services/Idi";
import groupAnnouncementService from "../../services/GroupAnnouncement";
import announcementService from "../../services/Announcement";
import { putFile } from "@/services/files";

export default {
  name: "ApplyResearchGroup",
  components: {
    ChosenUniversity,
    ModalDescriptionFile,
  },
  data() {
    return {
      groupToApply: {
        group: null,
        fileResume: "",
        fileEndorsement: "",
        fileFormulation: "",
      },
      participantGroups: [],
      showModalImageDescriptionOne: false,
      showModalImageDescriptionTwo: false,
      showModalImageDescriptionThree: false,
      modalApplyGroup: false,
      announcement: {
        announcement: {
          name: "",
          institution: { name: "" },
          description: "",
          category: { name: "" },
          dateInit: "",
          dateFinish: "",
          id: null,
        },
      },
    };
  },
  validations: {
    groupToApply: {
      group: { required },
      fileResume: { required },
      fileEndorsement: { required },
      fileFormulation: { required },
    },
  },
  created() {
    this.getAllInfo();
  },
  methods: {
    async getAllInfo() {
      try {
        this.participantGroups = await idiService.listGroupsUser();
        this.announcement = await announcementService.getSpecificAnnouncement(
          this.$route.params.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    openModalImageDescriptionPdfOne() {
      this.showModalImageDescriptionOne = true;
    },
    openModalImageDescriptionPdfTwo() {
      this.showModalImageDescriptionTwo = true;
    },
    openModalImageDescriptionPdfThree() {
      this.showModalImageDescriptionThree = true;
    },
    closeModalDescriptionImageOne() {
      this.showModalImageDescriptionOne = false;
    },
    closeModalDescriptionImageTwo() {
      this.showModalImageDescriptionTwo = false;
    },
    closeModalDescriptionImageThree() {
      this.showModalImageDescriptionThree = false;
    },
    uploadFileInfoOne(info) {
      this.groupToApply.descriptionFileResume = info.description;
      this.groupToApply.fileResume = info.file;
      this.showModalImageDescriptionOne = false;
    },
    uploadFileInfoTwo(info) {
      this.groupToApply.descriptionFileEndorsement = info.description;
      this.groupToApply.fileEndorsement = info.file;
      this.showModalImageDescriptionTwo = false;
    },
    uploadFileInfoThree(info) {
      this.groupToApply.descriptionFileFormulation = info.description;
      this.groupToApply.fileFormulation = info.file;
      this.showModalImageDescriptionThree = false;
    },
    showModalApplyGroup() {
      this.modalApplyGroup = true;
    },
    async submitApplication() {
      this.$store.dispatch("fetchLoading", true);
      let urlFileOne = null;
      try {
        urlFileOne = await putFile(
          this.groupToApply.fileResume,
          "groupToApply",
          `${this.groupToApply.fileResume.name}`
        );
        this.groupToApply.fileUrlOne = urlFileOne;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo 1",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      let urlFileTwo = null;
      try {
        urlFileTwo = await putFile(
          this.groupToApply.fileEndorsement,
          "groupToApply",
          `${this.groupToApply.fileEndorsement.name}`
        );
        this.groupToApply.fileUrlTwo = urlFileTwo;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo 2",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      let urlFileThree = null;
      try {
        urlFileThree = await putFile(
          this.groupToApply.fileFormulation,
          "groupToApply",
          `${this.groupToApply.fileFormulation.name}`
        );
        this.groupToApply.fileUrlThree = urlFileThree;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo 3",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      try {
        const obj = {
          groupId: this.groupToApply.group,
          announcementId: this.$route.params.id,
          resume: {
            state: false,
            url: this.groupToApply.fileUrlOne,
          },
          endorsement: {
            state: false,
            url: this.groupToApply.fileUrlTwo,
          },
          formulation: {
            state: false,
            url: this.groupToApply.fileUrlThree,
          },
        };
        await groupAnnouncementService.applyForResearchGroup(obj);
        this.$router.push({
          name: "DetailAnnouncementResearchProfessor",
          params: { id: this.$route.params.id },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./ApplyResearchGroup.scss"></style>