import { render, staticRenderFns } from "./ApplyResearchGroup.html?vue&type=template&id=2bb46176&scoped=true&"
import script from "./ApplyResearchGroup.vue?vue&type=script&lang=js&"
export * from "./ApplyResearchGroup.vue?vue&type=script&lang=js&"
import style0 from "./ApplyResearchGroup.scss?vue&type=style&index=0&id=2bb46176&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb46176",
  null
  
)

export default component.exports